'use strict';

angular.module("aerosApp")
	.factory('changeOrganizationModal', [ "$uibModal", function ($uibModal) {

		function open(organizationId) {
			var modal = $uibModal.open({
                size: "md",
				templateUrl: "/static/templates/org/modal/changeOrganizationModal.html",
				controller: "changeOrganizationModalCtrl",
                windowClass: "zindex2050",
                resolve: {
					orgId: function () { return organizationId; }
				}
			});
			return modal.result;
		}

		return {
			open: open
		}
	}])
	.controller("changeOrganizationModalCtrl", [ "$scope", "$uibModalInstance", "$http", "orgId","CommonService",
		function($scope, $uibModalInstance, $http, orgId, CommonService) {
			$scope.organizations = [{name: "loading...", id:0}];
            $http.get(CommonService.baseUrl + "/organization/")
                .then(function (results) {
                    $scope.organizations = _.sortBy(results.data.organizations, function (o) {
                        return o.name.toLowerCase();
                    });
                });

			$scope.selection = {
				newOrganization: orgId
			};
			$scope.cancel = function() {
				$uibModalInstance.dismiss();
			};
			$scope.selectNewOrganization = function() {
				$uibModalInstance.close($scope.organizations.find(function(item){
				    return $scope.selection.newOrganization == item.id;
                }));
			};
		}
	]);
